import { Link } from "@remix-run/react";
import { Brain, CalendarRange, Clock4, Lightbulb } from "lucide-react";
import { BsChevronCompactDown } from "react-icons/bs";
import { LuArrowBigRight } from "react-icons/lu";
import { dateToString, getMondayOfDate, getNextMonday } from "~/utils/dates";
import { backgroundImage } from "./backgroundLanding";
import "./landing.css";
import { Card, CardContent } from "./ui/card";

const HOW_IT_WORKS_ITEMS = [
  {
    icon: Clock4,
    title: "Define Preferences",
    description: "Quickly set your time, cuisine, and special requests",
  },
  { icon: Lightbulb, title: "Get New Ideas", description: "Discover healthy and delicious meal suggestions" },
  {
    icon: CalendarRange,
    title: "Plan Your Week",
    description: "Plan your weekly menu with ingredients and recipes",
  },
  {
    icon: Brain,
    title: "Smart Suggestions",
    description: "FeedUs learns and improves recommendations over time",
  },
];

const HowItWorks = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6">
      {HOW_IT_WORKS_ITEMS.map((item, index) => (
        <Card key={index} className="bg-base-100 py-4">
          <CardContent className="p-6 flex flex-col items-center text-center">
            <item.icon className="h-12 w-12 text-purple-600 mb-4" />
            <h3 className="font-semibold text-lg mb-2 text-purple-900">{item.title}</h3>
            <p className="text-sm text-secondary">{item.description}</p>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export const Landing = ({
  hasUser,
  hasMenuNextWeek,
  hasMenuThisWeek,
}: {
  hasUser: boolean;
  hasMenuThisWeek?: boolean;
  hasMenuNextWeek?: boolean;
}) => {
  const thisMonday = dateToString(getMondayOfDate());
  const nextMonday = dateToString(getNextMonday());
  return (
    <>
      <section
        className="bg-right bg-cover relative"
        style={{
          height: "calc(100vh - 64px)",
          backgroundImage: backgroundImage,
        }}
      >
        <div className="pt-[12vh] lg:pt-[20vh] px-6 mx-auto flex flex-wrap flex-col items-center">
          <div className="flex flex-col w-full xl:w-3/5 justify-center lg:items-start overflow-y-hidden">
            <h1 className="my-4 text-3xl md:text-5xl text-primary font-bold leading-tight text-center md:text-left slide-in-bottom-h1">
              The Meal Planner for Families
            </h1>
            <p className="leading-normal text-base md:text-2xl mb-8 text-center md:text-left slide-in-bottom-subtitle">
              Effortlessly craft meal plans, grocery list and recipes in minutes, tailored for busy parents
            </p>

            <div className="pb-8 lg:pb-6 text-center md:text-left fade-in mt-4 ">
              {hasMenuThisWeek && (
                <Link role="button" className="btn btn-primary btn-lg" to={`/plan/${thisMonday}`}>
                  This week's menu <LuArrowBigRight size={20} />
                </Link>
              )}
              {hasMenuThisWeek && <div className="italic text-base-content/60 my-4">or</div>}
              <Link
                role="button"
                className="btn btn-primary btn-lg"
                to={(hasMenuNextWeek ? "/plan" : "/plan/explore") + `/${nextMonday}`}
              >
                {hasMenuNextWeek ? "Next week's menu" : "Plan next week"} <LuArrowBigRight size={20} />
              </Link>
            </div>
          </div>
          <div className="absolute flex items-center flex-col pb-1 fade-in bottom-4 md:bottom-0">
            <div
              className="pb-1"
              onClick={(e) => {
                e.currentTarget.scrollIntoView({ behavior: "smooth" });
              }}
            >
              How it works?
            </div>
            <BsChevronCompactDown />
          </div>
        </div>
      </section>
      <section className="xl:w-2/3 lg:mx-auto mt-4">
        <HowItWorks />
        <div className="flex justify-center mt-14">
          <Link role="button" className="btn btn-primary" to="/config">
            Get started for free
            <LuArrowBigRight size={18} />
          </Link>
        </div>
      </section>
    </>
  );
};
